import React,{useContext, useEffect, useState} from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { client } from "./client";
import {marked} from 'marked'
import DOMPurify from 'dompurify'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  borderRadius:'20px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const getHTMLData = (rawdata) => {
  const htmlString = marked(rawdata)
  const santizedHTMLString = DOMPurify.sanitize(htmlString)
  return santizedHTMLString
}
export default function Team({ hasTitle, bgColor }) {
  const [TeamMembers, setTeamMembers]=useState([])
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [description,setDescription] = useState()
  const [title,setTitle] = useState()
  const [image,setImage] = useState()
  const [name,setName] = useState()
  const [title5, setTitle5] = React.useState(false);
  const [description5, setDescription5] = React.useState(false);

  const handleOpen = (description,name,jobtitle,image) => {
    setDescription(description);
    setName(name);
    setTitle(jobtitle);
    setImage(image);
    setOpen(true)};
  const handleClose = () => setOpen(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);

  useEffect(()=>{
    const getAllEntries2 = async () =>{
        try { 
            await client.getEntries({
                content_type: "landingPage"
              }).then((entries) =>{
                console.log(entries.items[0].fields)
            
                setTitle5(entries.items[0].fields.titleSection5)
                setDescription5(getHTMLData(entries.items[0].fields.descriptionSection5))

               
            })
        }  catch(error){
            console.log("error");
        }
    }
    getAllEntries2()
  },[])

  useEffect(()=>{
    const getAllEntries = async () =>{
        try { 
            await client.getEntries({
                content_type: "team"
              }).then((entries) =>{
                console.log(entries)
                setTeamMembers(entries)
                  setDescription(getHTMLData(entries.items[0].fields.description))
            })
        }  catch(error){
            console.log("error");
        }
    }
    getAllEntries()
},[])
  return (
    <>
      <section id="team" className={`team-member-section ptb-100 ${bgColor}`}>
     
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-8">
              <div className="section-heading text-center mb-5">
                
                <h2>{title5}</h2>
                <p style={{textAlign:'justify'}}>
                <div dangerouslySetInnerHTML= {{__html: description5}}/>
                </p>
              </div>
            </div>
          </div>
          <div className="row" style={{justifyContent:'center'}}>
          {TeamMembers.items?.map((post)=>    
     
        
     <div class="profile" key={post.sys.id}>
     <div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <img
                    src={post.fields.profileImage.fields.file.url}
                    alt="Team Members"
                    className="img-fluid rounded-circle"
                  />
                </div>
                <div className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                  <h5 style={{fontSize:'15px'}} className="mb-0">{post.fields.name}</h5>
                  <h6 style={{fontSize:'14px'}}>{post.fields.jobTitle}</h6>
                  <ul className="list-inline team-social social-icon mt-4 text-white">
                  
                 
                    <li className="list-inline-item" onClick={() => handleOpen(post.fields.info,post.fields.name,post.fields.jobTitle)} target="blank">
                    
                      <a >
                      <span >
                      <img
                      style={{width:'20px',top:'2px',position:'relative'}}
                    src="assets/img/info.svg"
                    
                  />
                  
                    </span>
                      </a>
                    </li>
              
                    
                  </ul>
                </div>
              </div>
            </div>
          
     </div>
  
  )}
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" style={{color:'black'}} variant="h5" component="h2">
          {name} 
          </Typography>
          <Typography id="modal-modal-title" style={{fontSize:'16px'}} component="h4">
          {title} 
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <div dangerouslySetInnerHTML= {{__html: description}}/>         </Typography>
        </Box>
      </Modal>
         
          
           
            
          </div>
          <br/><br/>
        
        </div>
      </section>
    </>
  );
}
/*
<div className="col-lg-3 col-sm-6">
              <div className="single-team-member position-relative">
                <div className="team-image">
                  <img
                    src="assets/img/chris.png"
                    alt="Team Members"
                    className="img-fluid rounded-circle"
                  />
                </div>
                <div className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                  <h5 style={{fontSize:'15px'}} className="mb-0">Chris Ashe</h5>
                  <h6 style={{fontSize:'14px'}}>Director</h6>
                  <ul className="list-inline team-social social-icon mt-4 text-white">
                    <li className="list-inline-item">
                      <a href="https://www.linkedin.com/in/chris-ashe-07b3b920/">
                        <span className="ti-linkedin"></span>
                      </a>
                    </li>
                    
                    <li className="list-inline-item">
                      <a href="https://twitter.com/chrisashe1">
                        <span className="ti-twitter"></span>
                      </a>
                    </li>
                 
                    <li className="list-inline-item" onClick={handleOpen} >
                    
                      <a >
                      <span >
                      <img
                      style={{width:'20px',top:'2px',position:'relative'}}
                    src="assets/img/info.svg"
                    
                  />
                    </span>
                      </a>
                    </li>
                   <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" style={{color:'black'}} variant="h5" component="h2">
           Chris Ashe 
          </Typography>
          <Typography id="modal-modal-title" style={{fontSize:'16px'}} component="h4">
           Director of The European Institute for Innovation – Technology 
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
           His role extends to international technology/knowledge transfer for wherein he brokers resources for business, education, research and public authorities. Chris Ashe leads a vibrant, dynamic and confident Institution that delivers outstanding performance, and is highly regarded and sought after by partners locally, nationally and internationally. 
          </Typography>
        </Box>
      </Modal>
                    
                  </ul>
                </div>
              </div>
            </div>
            */