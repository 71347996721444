import React, { useState, useEffect } from "react";
import BlogCard from "../components/blogs/BlogCard";
import Pagination from "../components/blogs/Pagination";
import FooterTwo from "../components/layout/footer/FooterTwo";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/team/PageHeader";
import Features from "../components/features/Features";
import { client } from "../components/team/client";


import ProjectRight from "../components/features/ProjectRight";
import ScienceDirectArticles from "./Articles";


export default function Publications() {
   const [publications, setPublications]=useState([])
  
  
    useEffect(()=>{
      const getAllEntries = async () =>{
          try { 
              await client.getEntries({
                  content_type: "publications"
                }).then((entries) =>{
                  console.log(entries)
                  setPublications(entries)
                
              })
          }  catch(error){
              console.log("error");
          }
      }
      getAllEntries()
    },[])
  return (
    <Layout>
      <Navbar darkBg />
      
      <PageHeader
        HeaderTitle="Scientific publications"
     
      />
    <section id="publications" className="feature-section ptb-100">
        <div className="container">
          <div className="row align-items-center justify-content-between">
         
          {publications.items?.map((post)=> (
              <>
              <div  key={post.sys.id} style={{padding:'50px',backgroundColor:'#efefef',borderRadius:'20px'}}>
                <h5 style={{color:'black'}}>{post.fields.tilte}</h5>
                <p> <b style={{fontWeight:'600'}}>Authors: </b><b style={{color:'#2688c2'}}>{post.fields.authors}</b></p>
                <b style={{fontWeight:'600'}}>Abstract</b>
                <p>{post.fields.description}</p>
                <p> <b style={{fontWeight:'600'}}>Published in:</b> {post.fields.publishedIn}</p>
                <a className='btn solid-btn' target="_blank" style={{padding:'8px',borderRadius:'6px'}} href={post.fields.link}>Read More</a>
              </div>
              <br/>

          
               </>
            ))}
                
          </div>
        </div>
      </section>

      <FooterTwo />
    </Layout>
  );
}
