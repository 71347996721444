import React,{useContext, useEffect, useState} from "react";
import { client } from "./client";
import {marked} from 'marked'
import DOMPurify from 'dompurify'

export const getHTMLData = (rawdata) => {
  const htmlString = marked(rawdata)
  const santizedHTMLString = DOMPurify.sanitize(htmlString)
  return santizedHTMLString
}
export default function TeamMember() {
    const [description,setDescription] = useState()
    const [leader,setLeader] = useState()
    const [lnkd,setLinkedin] = useState()
    const [email,setEmail] = useState()
    const [Photo,setPhoto] = useState()
    const [desc,setDesc] = useState()

   useEffect(()=>{
      const getAllEntries = async () =>{
          try { 
              await client.getEntries({
                  content_type: "leaderhipBoard"
                }).then((entries) =>{
                  console.log(entries)
                  setLeader(entries.items[0].fields.title)
                  setDesc(entries.items[0].fields.role)
                  setLinkedin(entries.items[0].fields.linkedinlink)
                  setEmail(entries.items[0].fields.email)
                  setPhoto(entries.items[0].fields.image.fields.file.url)
                    setDescription(getHTMLData(entries.items[0].fields.description))
              })
          }  catch(error){
              console.log("error");
          }
      }
      getAllEntries()
  },[])
  return (
    <>
      <section className="team-single-section ptb-100">
      <div className="section-heading text-center mb-5">
                
                <h2>The Leadership Board</h2>
              
              </div>
        <div className="container">
         
          <br/>
          <div className="row justify-content-center">
            <div className="col-md-12 col-sm-12 col-lg-3" id='chris'>
              <div className="team-single-img">
                <img
                  src={Photo}
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />
              </div>
            </div>
            
            <div className="col-md-12 col-sm-12 col-lg-6">
              <div className="team-single-text">
                <div className="team-name mb-4">
                  <h4 className="mb-1">{leader}</h4>
                  <span style={{fontWeight:'500'}}>{desc}</span>
                </div>
                <ul className="team-single-info">
             
             <li className="list-inline-item">
               <a href={lnkd} className="color-primary">
                 <span className="ti-linkedin"></span>
               </a>
             </li>
             <li>
               <strong>Email:</strong>
               <span> {email}</span>
             </li>
           </ul>
                <div className="text-content mt-20">
                  <br/>
                  <div dangerouslySetInnerHTML= {{__html: description}}/>   </div>
               
              </div>
            </div>
          </div>
       
       
              
            
         
        </div>
      </section>
    </>
  );
}


/*
 <div className="row justify-content-center">
            <div className="col-md-12 col-sm-12 col-lg-3" id='chris'>
              <div className="team-single-img">
                <img
                  src="assets/img/chris.png"
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-lg-6">
              <div className="team-single-text">
                <div className="team-name mb-4">
                  <h4 className="mb-1">Chris Ashe</h4>
                  <span style={{fontWeight:'500'}}>Director</span>
                </div>
                <ul className="team-single-info">
             
                  <li className="list-inline-item">
                    <a href="https://twitter.com/chrisashe1" className="color-primary">
                      <span className="ti-twitter"></span>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://www.linkedin.com/in/chris-ashe-07b3b920/" className="color-primary">
                      <span className="ti-linkedin"></span>
                    </a>
                  </li>
                  <li>
                    <strong>Email:</strong>
                    <span> &nbsp;c.ashe@eifi-tech.eu</span>
                  </li>
                </ul>
                <div className="text-content mt-20">
                  <br/>
                  <p>
                  His role extends to international technology/knowledge transfer for wherein he brokers resources for business, education, research and public authorities. Chris Ashe leads a vibrant, dynamic and confident Institution that delivers outstanding performance, and is highly regarded and sought after by partners locally, nationally and internationally. 
                  </p>
                 
                </div>
              
              </div>
            </div>
          </div>
*/