import React from "react";
import { Routes, Route } from "react-router-dom";
import ChangePassword from "../components/others/ChangePassword";
import ComingSoon from "../components/others/ComingSoon";
import LoginTwo from "../components/others/LoginTwo";
import NotFound from "../components/others/NotFound";
import PasswordReset from "../components/others/PasswordReset";
import ThankYou from "../components/others/ThankYou";
import Login from "../pages/Login";
import HomeOne from "../themes/index-1/HomeOne";
import HomeTwo from "../themes/index-2/HomeTwo";
import HomeThree from "../themes/index-3/HomeThree";
import HomeFour from "../themes/index-4/HomeFour";
import HomeFive from "../themes/index-5/HomeFive";
import HomeSix from "../themes/index-6/HomeSix";
import HomeSeven from "../themes/index-7/HomeSeven";
import HomeEight from "../themes/index-8/HomeEight";
import OurTeam from "../pages/OurTeam";
import TeamDetails from "../pages/TeamDetails";
import Download from "../pages/Download";
import Review from "../pages/Review";
import Faq from "../pages/Faq";
import SignupTwo from "../components/others/SignupTwo";
import SignupPage from "../pages/SignupPage";
import BlogGrid from "../pages/BlogGrid";
import Publications from "../pages/Publications";
import BlogWithSidebar from "../pages/BlogWithSidebar";
import BlogDetails from "../pages/BlogDetails";
import ScrollToTop from "./ScrollToTop";

const AppRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomeFive />} />
        <Route path="/projects" element={<BlogGrid/>} />
        <Route path="/publications" element={<Publications/>} />
        <Route path="/blog-details" element={<BlogDetails/>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
